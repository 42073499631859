import Vue from 'vue';
import VueRouter from 'vue-router';
import Today from "../components/Today.vue";
import {firebase} from "../firebase";
import store from "../store";

Vue.use(VueRouter)







const router = new VueRouter({
  mode: 'history',
  routes: [{
      path: '/',
      name: 'today',
      component: Today,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/archives',
      name: 'archives',
      component: () => import('../components/Archive.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/annules',
      name: 'annulés',
      component: () => import('../components/Canceled.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('../components/Dashboard.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/hotel',
      name: 'hotel',
      component: () => import('../components/Hotel.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/prix',
      name: 'prix',
      component: () => import('../components/Prix.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/spa',
      name: 'spa',
      component: () => import('../components/Spa.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../components/Login.vue'),
      meta: {
        loginn: true
      }
    },
    {
      // catch all 404
      path: "*",
      component: () => import("../components/NotFound.vue"),
      meta: {
        requiresAuth: true
      }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const loginn = to.matched.some(record => record.meta.loginn);
  if (requiresAuth && !await firebase.getCurrentUser()) {
    store.commit('setLoggedIn', false);
    next('/login');
  } else if (requiresAuth && await firebase.getCurrentUser()) {
    store.commit('setLoggedIn', true);
    next();
  } else if (loginn && !await firebase.getCurrentUser()) {
    store.commit('setLoggedIn', false);
    next();
  } else if (loginn && await firebase.getCurrentUser()) {
    store.commit('setLoggedIn', true);
    next('/');
  }
});




export default router