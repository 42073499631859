import Vue from 'vue'
import Buefy from 'buefy'
import App from './App.vue'
// import * as firebase from "firebase";
import {
  firestorePlugin
} from 'vuefire'
import 'buefy/dist/buefy.css'
import router from './router'
import AsyncComputed from 'vue-async-computed'
// import store from "./store";
import './registerServiceWorker'

Vue.use(firestorePlugin)
Vue.use(Buefy)
Vue.use(AsyncComputed)

Vue.config.productionTip = false

// firebase.auth().onAuthStateChanged(user => {
//   store.dispatch("fetchUser", user);
// });

new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount('#app')