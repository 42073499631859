import firebase from "firebase/app";
import 'firebase/messaging';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBVQfmzyh8kU6TWBFvd38kLawQdXbceTyA",
    authDomain: "outofmedina.firebaseapp.com",
    databaseURL: "https://outofmedina.firebaseio.com",
    projectId: "outofmedina",
    storageBucket: "outofmedina.appspot.com",
    messagingSenderId: "322257338784",
    appId: "1:322257338784:web:eb690cc72545eb055f24c5",
    measurementId: "G-5N779YT167"
};

firebase.initializeApp(firebaseConfig);

firebase.getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            // console.log('FIREBASE AUTH GETTER TRIGGERED');
            unsubscribe();
            resolve(user);
        }, reject);
    })
};

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
  .then(function() {
  })
  .catch(function(error) {
    console.log(error.code);
    console.log(error.message);
  });

const db = firebase.firestore();

export {
    db,
    firebase
};