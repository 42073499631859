<template>
  <div id="app">
    <!-- <button @click="getMessagingToken()">PUSH</button> -->
    <router-view v-if="isMobile()" />
    <div class="nav-w-w">
      <!-- <div class="nav-w"> -->
      <div class="nav-w" v-if="store.state.loggedIn">
        <div class="left-w">
          <a
            href="https://app.elloha.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button class="button leftNavBtn is-warning is-light is-rounded">
              ELLOHA
            </button></a
          >
          <a
            href="https://www.outofmedina.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button class="button leftNavBtn is-warning is-light is-rounded">
              OOM
            </button></a
          >
        </div>

        <div class="nav">
          <router-link class="button is-link navBtn is-light is-rounded" to="/"
            >services en attente</router-link
          >
          <router-link
            class="button is-link navBtn is-light is-rounded"
            to="/archives"
            >services réglés</router-link
          >
          <router-link
            class="button is-link navBtn is-light is-rounded"
            to="/annules"
            >services annulés</router-link
          >
          <router-link
            class="button is-link navBtn is-light is-rounded"
            to="/hotel"
            >hotel</router-link
          >
          <router-link
            class="button is-link navBtn is-light is-rounded"
            to="/prix"
            >prix</router-link
          >
          <router-link
            class="button is-link navBtn is-light is-rounded"
            to="/spa"
            >Spa</router-link
          >
          <router-link
            class="button is-link navBtn is-light is-rounded"
            to="/dashboard"
            >statistiques</router-link
          >
        </div>
        <!-- <v-icon>fas fa-lock</v-icon> -->
        <div class="logout-w">
          <button
            class="button logoutBtn is-danger is-light is-rounded"
            v-bind:class="{ 'is-loading': loading }"
            @click="signOut()"
          >
            <span class="icon is-small">
              <i class="fas fa-sign-out-alt fa-lg"></i>
            </span>
          </button>
        </div>
      </div>
    </div>

    <router-view v-if="!isMobile()" />
  </div>
</template>

<script>
import store from "./store";
import firebase from "firebase";
import axios from "axios";

const { messaging } = firebase;

export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    store: function () {
      return store;
    },
  },
  mounted() {
    this.listenTokenRefresh();
  },
  methods: {
    signOut() {
      this.loading = true;
      setTimeout(() => {
        firebase
          .auth()
          .signOut()
          .then(() => {
            setTimeout(() => {
              this.loading = false;
              this.$router.replace({ path: "/login" });
            }, 500);
          });
      }, 500);
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    getMessagingToken() {
      // messaging.getToken({vapidKey: "BHslKbH0l22wKQPyPD8Gm1Q9DWa9f-mP8sMSLqiatAQT3UWzjEwVdfiTy5qWXEi0N9WxPTgPkg_sADe2-s8arus"})
      messaging
        .getToken()
        .then(async (token) => {
          if (token) {
            const currentMessageToken = window.localStorage.getItem(
              "messagingToken"
            );
            console.log("token will be updated", currentMessageToken != token);
            if (currentMessageToken != token) {
              await this.saveToken(token);
            }
          } else {
            console.log(
              "No Instance ID token available. Request permission to generate one."
            );
            this.notificationsPermisionRequest();
          }
        })
        .catch(function (err) {
          console.log("An error occurred while retrieving token. ", err);
        });
    },
    notificationsPermisionRequest() {
      messaging
        .requestPermission()
        .then(() => {
          this.getMessagingToken();
        })
        .catch((err) => {
          console.log("Unable to get permission to notify.", err);
        });
    },
    listenTokenRefresh() {
      const currentMessageToken = window.localStorage.getItem("messagingToken");
      console.log("currentMessageToken", currentMessageToken);
      if (currentMessageToken) {
        // if (!!currentMessageToken) {
        messaging.onTokenRefresh(function () {
          messaging
            .getToken()
            .then(function (token) {
              this.saveToken(token);
            })
            .catch(function (err) {
              console.log("Unable to retrieve refreshed token ", err);
            });
        });
      }
    },
    saveToken(token) {
      console.log("tokens", token);
      axios
        .post(
          `https://us-central1-cropchien.cloudfunctions.net/GeneralSubscription`,
          { token }
        )
        .then((response) => {
          window.localStorage.setItem("messagingToken", token);
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
@import url("https://use.typekit.net/zhj6lpd.css");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
#app {
  /* font-family: brandon-grotesque, sans-serif; */
  font-family: "Open Sans", sans-serif;
  /* text-transform: uppercase; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*::-webkit-scrollbar {
  display: none !important;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

.button.navBtn {
  z-index: 999;
  font-weight: 600 !important;
  transition: all 300ms;
  /* color: #552fbc !important; */
  /* background-color: #f2effb !important; */
  border: none;
  text-transform: uppercase;
}

.button.logoutBtn {
  height: 50px;
  width: 50px;
  margin-top: 0px !important;
}

.logout-w {
  position: absolute;
  margin-top: 0px !important;
  right: 15px;
  top: 0px;
  padding-top: 10px;
  height: 70px;
}

.left-w {
  position: absolute;
  margin-top: 0px !important;
  left: 15px;
  top: 0px;
  padding-top: 15px;
  height: 70px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.button.leftNavBtn {
  font-weight: 600 !important;
  margin-top: 0px !important;
}

.button.navBtn:focus {
  box-shadow: none !important;
}

.nav {
  padding-top: 15px;
  height: 70px;
  display: flex;
  justify-content: center;
  gap: 50px;
}

.button.navBtn.router-link-exact-active {
  color: #552fbc !important;
  background-color: transparent !important;
  font-weight: 700 !important;
  transform: scale(1.5);
}

@media only screen and (max-width: 768px) {
  .nav-w {
    display: flex;
  }
  .nav-w-w {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }
  .left-w {
    scroll-snap-align: center;
    scroll-snap-stop: always;
    position: initial;
    margin-top: 0px !important;
    margin-right: 60px;
    margin-left: 15px;
  }
  .logout-w {
    scroll-snap-align: center;
    scroll-snap-stop: normal;
    position: initial;
    margin-top: 0px !important;
    padding-right: 15px;
    margin-left: 60px;
  }

  .button {
    scroll-snap-align: center;
    scroll-snap-stop: always;
  }
}
</style>